import { Autocomplete, TextField, Button, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { getLanguageOptions } from "utils/utilFunctions";
import { useDispatch, useSelector } from "react-redux";

const GoogleTranslate = ({ lang = "en" }) => {
  // Initialize Google Translate widget{ value: "en", label: "English" }
  const [languageList, setLanguageList] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({ value: "en", lang: "English" });
  const { appConstants } = useSelector((state) => state.service);
  const googleTranslateElementInit = () => {
    const googleTranslateElement = new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  // const setLanguageOptions = async () => {
  //   const languageData = await getLanguageOptions();
  //   const languagesList = languageData.map((language, index) => ({
  //     value: language.language + index,
  //     id: language.language,
  //     label: language.name,
  //   }));
  //   console.log("000", languagesList);
  // };

  // useEffect(() => {
  //   const asyncFn = async () => await setLanguageOptions();
  //   asyncFn();
  // }, []);
  useEffect(() => {
    const cookies = new Cookies();
    const currentCookie = cookies.get("googtrans");
    if (currentCookie) {
      setSelectedLanguage(
        appConstants?.appLanguages?.find((lang) => lang.value === currentCookie?.split("/")[2]) || { value: "en", lang: "English" }
      );
    }
    const langList = appConstants?.appLanguages || [];
    setLanguageList([...langList].sort((a, b) => a.lang.localeCompare(b.lang)) || []);
  }, [appConstants]);
  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute("src", "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit");
    addScript.setAttribute("async", true);
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    return () => {
      // Clean up: Remove the script from the DOM
      document.body.removeChild(addScript);
    };
  }, []);
  const langChange = (e, newValue) => {
    e.preventDefault();
    if (newValue) {
      document.cookie = `googtrans=; max-age=-1; domain=${".teraobjects.com"}; path=/;`;
      document.cookie = `googtrans=; max-age=-1; domain=${""}; path=/;`;
      document.cookie = `googtrans=; max-age=-1; domain= mtq.teraobjects.com; path=/;`;
      document.cookie = `googtrans=/en/${newValue?.value || "en"}; expires=Thu, 01 Jan 2025 00:00:00 UTC; domain=${""}; path=/;`;
      document.cookie = `googtrans=/en/${
        newValue?.value || "en"
      }; expires=Thu, 01 Jan 2025 00:00:00 UTC; domain= mtq.teraobjects.com; path=/;`;
      // cookies.set("googtrans", `/en/${newValue?.value || "en"}`, {
      //   domain: `${window.location.hostname}`,
      //   path: "/",
      // });
      setSelectedLanguage(newValue);
      window.location.reload();
    }
  };
  return (
    <div class="notranslate">
      <Box sx={{ mr: 2 }}>
        {/* <div id="google_translate_element" /> */}

        <Autocomplete
          disablePortal
          size="small"
          options={languageList}
          fullWidth
          class="notranslate"
          value={selectedLanguage}
          isOptionEqualToValue={(option, value) => option.lang == value.lang}
          getOptionLabel={(option) => option.lang}
          sx={{ minWidth: 200 }}
          onChange={(e, newValue) => langChange(e, newValue)}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </div>
  );
};

export default GoogleTranslate;
